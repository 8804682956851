body {

  background-color: #212125;
  margin: 0;
  padding: 0;
  overflow: auto;
}
html {
  scroll-behavior: smooth;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button {
  cursor: pointer;
  animation: fadeOut2 ease-out 0.3s;
}
button:hover {
  opacity: 0.85;
  animation: fadeIn2 ease-in 0.3s;
}
.loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background-color: #212125;
}
#root {
  width: 100%;
  height: 100%;
  position: fixed;
}
#PopUp {
  position: fixed;
  width: 600px;
  height: 300px;
  top: calc(50vh - 150px);
  left: calc(50vw - 300px);
  background-color: white;
  z-index: 99999;
  opacity: 0.98;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  animation: appear 0.5s;
  box-shadow: 0px 10px 20px rgb(0 0 0 / 0.25);
  -webkit-transition: transform 0.4s;
  transition: transform 0.4s;
  transform: scale(1);
  display: flex;
}
.c-success  {
  width: 4vw;
  height: 4vw;
  background-image: url(./success.png);
  background-size: cover;
    display: inline-block;
}
.c-error {
  width: 4vw;
  height: 4vw;
  background-image: url("./error.png");
  background-size: cover;
    display: inline-block;
}
.footer {
    background-color: #212125;
    width: 100vw;
    display: flex;
    flex-direction: column;
    height: 13vh;
}
.headertxt {
  margin-bottom: 50vh;
}

.home-logo {
     height: 24px;
    width: 24px;
    padding: 4px;
    background-color: #cce04e;
    border: 2px solid #000;
    border-radius: 30px;
    flex-shrink: 0;
    background-image: url("./logo512.png");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
}
.mgmt20 {
  margin-top: -20px;
}
.mgmt50 {
  margin-top: -150px;
}
.mg0 {
  margin: 0;
}
.mgl5vw {
  margin-left: 5vw;
}
.mgl20 {
  margin-left: 20px;
}
.pd04 {
  padding: 0 4rem;
}
.mgt20 {
  margin-top: 20px;
}
.mgb20 {
  margin-bottom: 20px;
}
.mgb40 {
  margin-bottom:70px;
}

.social-icons {
  display: flex;
  grid-gap: 20px;
  margin-right: 40px;
  justify-content: flex-end;
}

.flex-gg-20 {
  grid-gap: 20px;
}

.flex-gg-5 {
  grid-gap: 5px;
}

.nav-bar {
  min-height: 60px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.green-button {
  min-width: 180px;
  min-height: 50px;
  background-color: #CBDE4D;
  border-radius: 15px;
  border-width: 0;
  font-family: 'Mouse Memoirs', monospace;
  font-size: 12px;
}
.purple-button {
  min-width: 180px;
  min-height: 50px;
  background-color: #2F3E28;
  border-radius: 15px;
  border-width: 0;
  font-family: 'Oswald';
  font-size: 12px;
  color: white;

  box-shadow: 20px 38px 34px -26px hsla(0,0%,0%,.2);
      border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
}

.address {
  min-width: 280px;
  min-height: 30px;
  border-radius: 5px;
  font-family: 'Oswald';
  padding-left: 5px;
}
.addresstxt {

}

.sm-buttom {
    width: 120px;
    height: 50px;
}

.sm-input {
    width: 120px;
    height: 48px;
    font-family: 'Mouse Memoirs', monospace;
    font-weight: 700;
    font-size: 12px;
    border-radius: 15px;
    border-width: 0;
    text-align: center;
      background-image: url('data:image/svg+xml;utf8,%3Csvg%20version%3D%221.1%22%20viewBox%3D%220%200%2050%2067%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20fill%3D%22none%22%20stroke-width%3D%222%22%3E%3Cline%20x1%3D%221%22%20x2%3D%2250%22%20y1%3D%2233.5%22%20y2%3D%2233.5%22%20stroke%3D%22%23D8D8D8%22%2F%3E%3Cpolyline%20transform%3D%22translate(25%2020)%20rotate(45)%20translate(-25%20-20)%22%20points%3D%2219%2026%2019%2014%2032%2014%22%20stroke%3D%22%23000%22%2F%3E%3Cpolyline%20transform%3D%22translate(25%2045)%20rotate(225)%20translate(-25%20-45)%22%20points%3D%2219%2052%2019%2039%2032%2039%22%20stroke%3D%22%23000%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  background-position: center right;
  background-size: contain;
  background-repeat: no-repeat;
}
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  opacity: 1 !important;
  background: transparent !important;
  border-width: 0px;
  margin: 0;
  border-left: 1px solid #d8d8d8;
  height: 48px;
  width: 34px;
  cursor: pointer;
}

.appErrorWrapper {
  display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
    background-color: red;
    width: 98vw;
    height: 98vh;
    left: 1vw;
    top: 1vh;
    opacity: 0.9;
    position: fixed;
    border-radius: 15px;

    transform: scale(1.0);
    animation: appear9 1s;
}

.flex-center {
    display: flex;
  justify-content: center;
  align-items: center;
}
.flex-col {
  flex-direction: column;
}
.flex-se {
  justify-content: space-evenly;
  width: 100%;
}
.full {
  width: 100vw;
  height: 100vh;
}
.main {
  padding: 0;
  justify-content: flex-start;
  position: fixed;
  z-index: 1;
  filter: brightness(1.12);
  width: 100vw;
  height:100vh;
  background-color: transparent;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  padding-bottom: 5vh;
  flex-direction: column;
  justify-content: baseline;
  background-image: url('./images/riders.png'),url('./images/treeleft.png'), url('./images/treeright.png'), url('./images/backgrounds.png');
  background-position: 50% 100%, -9% 100%, 102% 100%, center 0%;
  background-size: 45vw, contain, contain, cover;
  padding-bottom: 1vh;
  background-repeat: no-repeat,no-repeat,no-repeat,no-repeat;
}
.titleText {
  opacity: 0.9;
  margin-top: -5vh;
  height: 20vh;
}
.wallettxt {
  text-align: center;
}
.mintSec{
  min-height: 200px;
  margin-top: -5vh;
}
.width33 {
  width: 33vw;
}

.col-white {
  color: white;
}
.col-grey {
  color: #EBEBEB;
}
.sm-txt {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.md-txt {
  font-family: 'Oswald';
  letter-spacing: .15em;
  font-weight: 400;
  font-size: 24px;
  margin: 0px;
}
.lg-txt {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 3vw;
  line-height: 7vw;
  color: #2D3928;
}
.heading {
  position: absolute;
  top: -5px;
  left:  0;
  margin: 20px;
}

.paragraph {

  position: absolute;
  top: 100px;
  margin: 20px;

}
.mw70 {
  max-width: 70vw;
}
.txt-align-c {
  text-align: center;
}
.txt-align-l {
  text-align: left;
}
a {
  color: #2F3E28;
  text-decoration: underline;
}
a:hover{
  animation: fadeIn ease-in 0.5s;
  cursor: pointer;
  opacity: 0.7;
}
#walletPopUp {
  position: fixed;
  width: 800px;
  height: 300px;
  top: calc(50vh - 160px);
  left: calc(50vw - 400px);
  background-color: white;
  z-index: 99999;
  opacity: 0.98;
  border-radius: 20px;
  display: none;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  animation: appear 0.5s;
  box-shadow: 0px 10px 20px rgb(0 0 0 / 0.25);
  -webkit-transition: transform 0.4s;
  transition: transform 0.4s;
  transform: scale(0);
    padding-bottom: 10px;
}
.walletIcon {
  width:  110px;
  height: auto;
}
.wallet {
  background-color: rgb(0 0 0 / 0);
  border-radius: 20px;
  padding: 25px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 0px;
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s;
}
.wallet:hover {
  background-color: rgb(0 0 0 / 0.08);
  border-radius: 20px;
  padding: 25px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 0px;
  -webkit-transition: background-color 1s;
  transition: background-color s;
  cursor: pointer;
}
.walletError {
  background-color: rgb(242 99 103 / 0.7);
  border-radius: 20px;
  padding: 25px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 0px;
}
.diploma {
  margin-top: -50px;
}
.red-txt {
  color: red;
  margin-top: -2vh;
  margin-bottom:-2vh ;
  animation: flash 10s;
  opacity: 0;
}
.c-loader {
  animation: is-rotating 3s infinite;
  width: 4vw;
  height: 4vw;
  border: 0.9vw solid #eeeeee;
  border-top-color: #2F3E28;
  border-radius: 50%;
  display: inline-block;
}
@keyframes flash{
  0%{
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
@keyframes fadeIn {
  0% {opacity:1;}
  100% {opacity:0.7;}
}
@keyframes fadeOut {
  0% {opacity:0.7;}
  100% {opacity:1;}
}
@keyframes fadeIn2 {
  0% {opacity:1;}
  100% {opacity:0.85;}
}
@keyframes fadeOut2 {
  0% {opacity:0.85;}
  100% {opacity:1;}
}
@keyframes appear {
  from {transform: scale(0);opacity: 0;}
  to {transform: scale(1);opacity: 1;}
}
@keyframes appear9 {
  from {transform: scale(0);opacity: 0;}
  to {transform: scale(1);opacity: 0.9}}
@keyframes disappear {
  from {transform: scale(1);opacity: 1;}
  to {transform: scale(0);opacity: 0;}
}
@keyframes is-rotating {
  to {
    transform: rotate(1turn);
  }
}
.handwritten {
  font-family: 'Indie Flower', cursive;
  text-decoration: none;
  margin: 0;
  font-size: 16px;
}
.handwritten:hover {
  z-index: 100;
}
.ape {
  width: 40vh;
  position: absolute;
  bottom: 13vh;
  margin-bottom: 0;
  left: 5vw;
  pointer-events: none;
}
.walletBut {
  margin-top: 20px;
}
.fw700 {margin-top: 1vh;padding: 0 0.5rem;text-transform: uppercase; word-spacing: 3px; font-size: 14px; margin-bottom: 20px;}
@media only screen and (max-width: 1023px) {

.main {

    background-size: 65vw, 50vh, 45vh, cover;
  background-position: 50% 100%, -30% 100%, 115% 100%, center 0%;
}
.walletBut {
  margin-top: 100px;
}
.sm-txt {
  font-size: 10px;
}
.handwritten {
  font-size: 10px;
}
.mintSec {
  margin-top: 250px;
}
.walletBut {
  margin-top: 30px;
  margin-bottom: 0px;
}
.mintSec {
  margin-top: -50px;
}



}
@media only screen and (max-width: 800px) {
.main {

    background-size: 65vw, 50vh, 45vh, cover;
  background-position: 50% 100%, -60% 100%, 140% 100%, center 0%;
}
#walletPopUp{
    width: 350px;
    min-height: 500px;
    top: calc(50vh - 250px);
    left: calc(50vw - 175px);
    overflow: auto;
    justify-content: flex-start;
}
.sdark-txt {
  text-align: center;
}
.flex-se {
  flex-direction: column;
}
.pd04 {
  padding: 0 1rem;
}
.purple-button {
  margin: 0;

}
.walletBut {
  margin-top: 30px;
  margin-bottom: 0px;
}
.mintSec {
  margin-top: 0;
}
}
@media only screen and (max-width: 650px) {
  .lg-txt {
  font-size: 32px;
  margin-top: 25px;
}
.walletBut {
  margin-top: 30px;
  margin-bottom: 0px;
}
.mintSec {
  margin-top: -20px;
}
.c-success  {
  width: 10vw;
  height: 10vw;
  background-image: url(./success.png);
  background-size: cover;
    display: inline-block;
}
.c-error {
  width: 10vw;
  height: 10vw;
  background-image: url("./error.png");
  background-size: cover;
    display: inline-block;
}
}
@media only screen and (max-width: 550px) {
  .lg-txt {
  font-size: 28px;
  margin-top: 120px;
  left: 20px;
}
.nav-bar {
  justify-content: space-between;
  position: relative;
  top: 0;
}
.heading {
left: auto;
}
.social-icons {
  margin-right: 20px;
  grid-gap: 10px;
}
.flex-gg-5{
  flex-direction: column;
}
.sm-buttom {
      margin: 0;
}
.sm-input {
    margin: 0;
      margin-top: 20px;

}
.titleText {
  height: 100px;
  margin-top: 0;
}
.main {
    background-size: 90vw, 35vh, 35vh, cover;
  background-position: 50% 82.5%, -70% 100%, 170% 100%, center 0%;
  padding-bottom: 5vh;
}
#PopUp {
  width: 90vw;
  left: 5vw;
}
}
.mgt60 {
  margin-top: 60px;
}
